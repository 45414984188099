/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.site-logo img{
  width: 60px;
  display: inline;
}
.site-name {
  position: relative;
  top: -20px;
  margin: 5px;
  font-size: 1.5rem;
}

.best-products{
  /* border: 1px solid; */
  margin: 0 0 0 30px;
}
.best-product{
  /* border: 1px solid #eeeeee; */
  /* border-radius: 5px; */
  padding: 5px 0 0 0 ;
}

/* .products-product{
  height: 400px;
;} */
.products-header{
  background-repeat: no-repeat;
  background-position: center;
  height: 120px;
}
.languageEnglish{
  padding: 10px;
  margin: 2px;
}
.languageKorean{
  padding: 9px;
  margin: 2px;
}

.product-title{
  margin-top: 10px;
  height: 50px;
}
.product-description{
  height: 55px;
  overflow: hidden;
  text-align: left;
}

.product-detail p{
  font-size: 1rem;
}

.product-label{
  font-weight: bold;
}